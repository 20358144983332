stickyHeader();
mobileMenu();
themeSwitch();
scrollNext();
formSender();
formModal();


// GET COOKIE
function getCookie(name) {
	let matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
	return matches ? decodeURIComponent(matches[1]) : undefined;
}


// STICKY HEADER
function stickyHeader() {
	const header = document.querySelector("#site-header");
	const scrollUp = "sticky";
	const scrollDown = "sticky-up";
	let lastScroll = 0;

	window.addEventListener("scroll", () => {
		const currentScroll = window.pageYOffset;
		if (currentScroll <= 200) {
			header.classList.remove(scrollUp);
			header.classList.remove(scrollDown);
			return;
		}

		if (currentScroll > lastScroll && !header.classList.contains(scrollDown)) {
			// down
			header.classList.remove(scrollUp);
			header.classList.add(scrollDown);
		} else if (currentScroll < lastScroll && header.classList.contains(scrollDown)) {
			// up
			header.classList.remove(scrollDown);
			header.classList.add(scrollUp);
		}
		lastScroll = currentScroll;
	});
}

// MOBILE MENU
function mobileMenu() {
	let el = document.querySelectorAll('.menu-toggle');
	for (var i = 0; i < el.length; i++) {
		el[i].addEventListener('click', function () {
			document.querySelector('.main-navigation').classList.toggle('active');
			document.querySelector('.menu-open').classList.toggle('active');
			document.querySelector('.menu-bg').classList.toggle('active');
		});
	}
}

// MODALS
function formModal() {
	let modalBtn = document.querySelectorAll('.cnt-modal'),
	modalBlock = document.getElementById('modal'),
	form = modalBlock.querySelector('.modal-inner'),
	modalFormClose = modalBlock.querySelectorAll('.modal-toggle'),
	body = document.body;

	modalBtn.forEach(function (elem) {
		elem.addEventListener('click', function (e) {
			e.preventDefault();
			fadeIn(form);
		})
	});

	function fadeIn(e) {
		body.classList.add('open');
		modalBlock.classList.add('open');
		setTimeout(function () {
			e.classList.add('open');
		}, 100);
	}

	modalFormClose.forEach(function (close) {
		close.addEventListener('click', function () {
			form.classList.remove('open');
			setTimeout(function () {
				modalBlock.classList.remove('open');
				body.classList.remove('open');
			}, 400);

		})
	});

}

// SCROLL TO NEXT SECTION
function scrollNext() {
	let link = document.querySelectorAll('.part-hero .next');
	link.forEach(function (item) {
		item.addEventListener('click', function (evt) {
			evt.preventDefault();
			let offset = item.parentElement.parentElement.offsetHeight;
			console.log(parent);
			window.scrollTo({
				top: offset - 50,
				behavior: 'smooth'
			});
		});
	});
}

// SWITCH TO DARK THEME
function defaultMode() {
	if (getCookie('mode') == 'dark');
}


function themeSwitch() {
	let switcher = document.getElementById('switch-theme'),
	mode = 'dark';

	if (getCookie('mode') == 'dark') {
		switcher.checked = true;
		document.body.classList.add(mode);
	}

	switcher.addEventListener('change', () => {
		document.body.classList.toggle('dark');
		if (switcher.checked == true) {
			mode = 'dark';
		} else {
			mode = 'white';
		}

		document.cookie = 'mode=' + mode + '; max-age=2592000‬';
	});

}

// PARSE PHONE DATA
function postData(url = '', data) {
	return fetch(url, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, cors, *same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			// headers: {
				// 'Content-Type': 'application/json',
				// 'Content-Type': 'application/x-www-form-urlencoded',
				// 'Content-Type': 'multipart/form-data',
			// },
			redirect: 'follow', // manual, *follow, error
			referrer: 'no-referrer', // no-referrer, *client
			body: data, // тип данных в body должен соответвовать значению заголовка "Content-Type"
		})
		.then(response => response.json()); // парсит JSON ответ в Javascript объект
	}


// FORM SENDER
function formSender() {
	let Forms = document.forms;

	if (Forms) {
		for (form of Forms) {
			let action = form.action;
			form.addEventListener('submit', function (event) {
				event.preventDefault();

				let phone = this.elements.namedItem('user_phone'),
				email = this.elements.namedItem('user_email');
				param = new FormData(this);

				if ((phone && phone.value) || (email && email.value)) {
					if (phone && phone.value && phone.value.length < 10) {
						phone.classList.add('has-err');
						console.log('sendForm');
						this.querySelector('.valid-tel').classList.add('has-err');
						console.log('phone')
					} else if (email && email.value && email.value.length < 5) {
						console.log(email.validity);
						email.classList.add('has-err');
						this.querySelector('.valid-email').classList.add('has-err');
					} else {
						console.log(param)
						sendFetch(this, action, param);
					}
				} else {
					this.querySelector('.blank-err').classList.add('has-err');
					if(phone) {
						phone.classList.add('has-err');
					}
					if (email) {
						email.classList.add('has-err');
					}

				}
			});

			form.addEventListener('keydown', function () {
				let err = this.querySelectorAll('.has-err');
				err.forEach(el => el.classList.remove('has-err'));
			});
		}
	}
}

function sendFetch(t, a, param) {
	postData(a, param)
	.then(data => {
		console.log(data);
		t.reset();
		t.querySelector('.resp.ok').style.display = 'block';
		setTimeout(() => {
			t.querySelector('.resp.ok').style.display = 'none';
		}, 3500);

	})
	.catch(error => {
		console.error(error);
		t.reset();
		t.querySelector('.resp.err').style.display = 'block';
		setTimeout(() => {
			t.querySelector('.resp.err').style.display = 'none';
		}, 3500)
	});
}